import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { CLASS_NAMES } from "Services/ClassNames";
import url from "url";
import { usePaxSelector } from "./pax-selector/usePaxSelector";
import { useDateSelector } from "./date-selector/useDateSelector";
import { useEffect, useMemo, useState } from "Shared/haunted/CustomHooks";
import { COLOMBIA_COUNTRY_CODE, COMMON_DAYJS_FORMAT, COOKIES, FLIGHT_SELECT_URL, WIDGETS } from "Services/constants";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { getParsedProperty, getRequestUrl, toBoolean } from "Services/common";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { ApiStationSettings } from "Shared/models/ApiStationSettings";
import { JetSmartEvent } from "Services/eventbus/JetSmartEvent";
import { validate } from "Services/form-validation";
import SearchRequest from "Shared/models/SearchRequest";
import { useRouteSelector } from "./route-selector/useRouteSelector";
import { useTripTypeSelector } from "./trip-type-selector/useTripTypeSelector";
import { usePromoCodeBox } from "./promo-code-box/usePromoCodeBox";
import { ref } from "Components/directives/ref";
import { tealiumLogSearchClick } from "ComponentHelpers/SearchboxTealiumHelpers";
import { getCookie } from "Services/cookieHandling";
import { UserInfo } from "Shared/models/UserInfo";
import { useNavitaireSettings } from "ComponentHelpers/useNavitaireSettings";
import { ApiCultureSettings } from "ComponentModels/ApiCultureSettings";
import { decode } from "ComponentHelpers/PromoCodeHelper";
import classNames from "classnames";
import { usePreviousSearch } from "./usePreviousSearch";
import { tealiumLog, getTealiumDeviceType } from "Services/TealiumHelpers";

export const useShadowDOM = false;
export const name = "bb-searchbox";

export const observedAttributes: (keyof Attributes)[] = [
	"culture-settings",
	"culture",
	"currency",
	"dynamic-settings",
	"station-settings",
	"timetable-max-months-fallback",
];

export type PeruCompraStatus = "unknown" | "RegularUser" | "PeruCompraUser";

type TabType = "flights" | "hotels" | "cars" | "transfers";

interface Tab {
	iconClass: string;
	label: string;
	type: TabType;
	onClick: () => void;
}

export interface Attributes {
	"culture-settings": string;
	"culture": string;
	"currency": string;
	"dynamic-settings": string;
	"station-settings": string;
	"timetable-max-months-fallback": string;
}

export interface Props {
	culture: string;
	cultureSettings: ApiCultureSettings;
	currency: string;
	dynamicSettings: DynamicSettings;
	stationSettings: ApiStationSettings;
	timetableMaxMonthsFallback: number;
}

export const Component: HauntedFunc<Props> = (host) => {
	const props: Props = {
		culture: host.culture,
		cultureSettings:
			host.cultureSettings && typeof host.cultureSettings === "string"
				? getParsedProperty<ApiCultureSettings>(host.cultureSettings)
				: undefined,
		currency: host.currency,
		dynamicSettings:
			host.dynamicSettings && typeof host.dynamicSettings === "string"
				? getParsedProperty<DynamicSettings>(host.dynamicSettings)
				: undefined,
		stationSettings:
			host.stationSettings && typeof host.stationSettings === "string"
				? getParsedProperty<ApiStationSettings>(host.stationSettings)
				: undefined,
		timetableMaxMonthsFallback:
			host.timetableMaxMonthsFallback && typeof host.timetableMaxMonthsFallback === "string"
				? parseInt(host.timetableMaxMonthsFallback)
				: undefined,
	};

	const form = useRef<HTMLFormElement>(undefined);

	const [displayedTab, setDisplayedTab] = useState<TabType>("flights");
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
	const [peruCompraStatus, setPeruCompraStatus] = useState<PeruCompraStatus>("unknown");

	const navitaireSettings = useNavitaireSettings();

	const promoCodeBox = usePromoCodeBox({
		culture: props.culture,
		dynamicSettings: props.dynamicSettings,
		isInFocus: !isCollapsed,
		peruCompraStatus,
	});

	const startLoading = () => {
		const loaderInstance = window.newRtLoader(".dg-for-loader");
		loaderInstance.startLoader();
		return loaderInstance;
	};

	const stopLoading = (loader: any) => {
		if (loader) {
			loader.stopLoader();
			loader.destroy();
		}
	};

	const routeSelector = useRouteSelector({
		culture: props.culture,
		currentPromoCode: promoCodeBox.promoCode,
		defaultRouteCountrySettings: props.dynamicSettings.DefaultRouteCountrySettings,
		peruCompraStatus,
		setIsCollapsed,
		startLoading,
		stopLoading,
	});

	const tripTypeSelector = useTripTypeSelector({ isHidden: isCollapsed });

	const datePicker = useDateSelector({
		culture: props.culture,
		isHidden: isCollapsed,
		isOneWay: tripTypeSelector.numberOfJourneys === 1,
		selectedDestination: routeSelector.selectedDestination?.information?.code,
		selectedOrigin: routeSelector.selectedOrigin?.information?.code,
		usePrices: props.dynamicSettings.ShowFarePricesCultures,
		timetableMaxMonthsFallback: props.timetableMaxMonthsFallback,
		startLoading,
		stopLoading,
	});

	const paxSelector = usePaxSelector({
		culture: props.culture,
		isFlightDomesticColombia:
			routeSelector?.selectedOriginCountryCode === COLOMBIA_COUNTRY_CODE &&
			routeSelector?.selectedDestinationCountryCode === COLOMBIA_COUNTRY_CODE,
	});

	const previousSearch = usePreviousSearch();

	const showReloadLink = useMemo(() => Boolean(previousSearch.load()), []);

	// Helpers

	const init = async () => {
		const country = window.location.pathname.slice(-6, -4).toUpperCase();
		const language = window.location.pathname.slice(-3, -1).toLowerCase();

		// DEVNOTE This is here because JET-9907
		tealiumLog({
			eventName: "homepage_dom_loaded",
			eventParams: {
				culture: `${language}-${country}`,
				device: getTealiumDeviceType(),
			},
			updateRealUdo: true,
		});

		const userInfo = getCookie(COOKIES.USER_INFO);

		previousSearch.refresh();

		try {
			const decodedUserInfo = userInfo ? (JSON.parse(decode(atob(userInfo))) as UserInfo) : undefined;
			const isPeruCompra =
				toBoolean(decodedUserInfo?.IsPeruCompraAdmin) || toBoolean(decodedUserInfo?.IsPeruCompraMember);

			setPeruCompraStatus(isPeruCompra ? "PeruCompraUser" : "RegularUser");
		} catch (e) {
			throw new Error("Unable to parse user info cookie.");
		}

		await navitaireSettings.init({
			culture: props.culture,
			loadedValues: {
				abTestSettings: window.JetSmart.AbTestSettings,
				cultureSettings: props.cultureSettings,
				currency: props.currency,
				stationSettings: props.stationSettings,
			},
		});
	};

	const getSearchRequest = () => {
		const cultureElements = props.culture.split("-", 2);
		const countryIndex = 1;
		const rc = cultureElements.length === 2 ? cultureElements[countryIndex] : "";

		let req: SearchRequest = {
			o1: routeSelector.selectedOrigin.information.code,
			d1: routeSelector.selectedDestination.information.code,
			dd1: datePicker.currentDeparture.format(COMMON_DAYJS_FORMAT),
			ADT: paxSelector.passengers.adults + paxSelector.passengers.teens,
			CHD: paxSelector.passengers.children,
			inl: paxSelector.passengers.infants,
			r: false,
			s: true,
			mon: true,
			cur: navitaireSettings.value.currency,
			culture: props.culture,
			pc: promoCodeBox.promoCode,
			rc,
			selectedPriceOutbound: datePicker.selectedPriceOutbound,
			selectedPriceInbound: "",
		};

		if (tripTypeSelector.numberOfJourneys === 2) {
			req = {
				...req,
				dd2: datePicker.currentReturn.format(COMMON_DAYJS_FORMAT),
				selectedPriceInbound: datePicker.selectedPriceInbound,
				r: true,
			};
		}

		return req;
	};

	const redirectToQueueItUrl = (requestUrl: string) => {
		const newLocationUrl = encodeURIComponent(
			url.resolve(window.JetSmart.Settings.BookingUrl, `${FLIGHT_SELECT_URL}${requestUrl}`)
		);

		window.location.href = window.JetSmart.DynamicSettings.QueueItPrefix + newLocationUrl;
	};

	const redirectToRegularUrl = (requestUrl: string) => {
		location.href = url.resolve(window.JetSmart.Settings.BookingUrl, `${FLIGHT_SELECT_URL}${requestUrl}`);
	};

	const raiseEventBusEvent = (id: string) => {
		window.eventBus.raiseEvent({
			name: JetSmartEvent.SearchBoxTabClick,
			params: {
				id,
				event: "click",
				data: {},
			},
		});
	};

	const savePreviousSearch = () => {
		previousSearch.save({
			departureDate: datePicker.currentDeparture.format(COMMON_DAYJS_FORMAT),
			destination: routeSelector.selectedDestination.information.code,
			origin: routeSelector.selectedOrigin.information.code,
			passengers: {
				adults: paxSelector.passengers.adults,
				children: paxSelector.passengers.children,
				infants: paxSelector.passengers.infants,
				teens: paxSelector.passengers.teens,
			},
			returnDate: datePicker.currentReturn?.format(COMMON_DAYJS_FORMAT) || "",
		});
	};

	// Event listeners

	const handleReloadSearch = async (e: MouseEvent) => {
		e.preventDefault();

		const value = previousSearch.load();

		if (!value) return;

		try {
			const shouldUpdateRoute =
				value.origin !== routeSelector.selectedOrigin?.information?.code ||
				value.destination !== routeSelector.selectedDestination?.information?.code;

			const shouldUpdateDates =
				value.departureDate !== datePicker.currentDeparture?.format(COMMON_DAYJS_FORMAT) ||
				value.returnDate !== datePicker.currentReturn?.format(COMMON_DAYJS_FORMAT);

			if (shouldUpdateRoute) {
				routeSelector.updateOnSearchReload(value.origin, value.destination);
			}

			if (shouldUpdateRoute || shouldUpdateDates) {
				await datePicker.updateOnSearchReload(
					value.origin,
					value.destination,
					value.departureDate,
					value.returnDate
				);
			}

			tripTypeSelector.updateOnSearchReload(value.returnDate ? 2 : 1);
			paxSelector.updateOnSearchReload(value.passengers);
		} catch {
			// Do nothing
		}
	};

	const handleFormSubmit = (e: MouseEvent) => {
		e.preventDefault();

		tealiumLogSearchClick({
			adults: paxSelector.passengers.adults,
			children: paxSelector.passengers.children,
			culture: props.culture,
			departureDate: datePicker.currentDeparture,
			destinationCode: routeSelector.selectedDestination?.information.code,
			infants: paxSelector.passengers.infants,
			numberOfJourneys: tripTypeSelector.numberOfJourneys,
			market:
				routeSelector.selectedDestinationCountryCode !== routeSelector.selectedOriginCountryCode
					? "INTER"
					: `DOM-${routeSelector.selectedOriginCountryCode}`,
			originCode: routeSelector.selectedOrigin?.information.code,
			preloadedPromoCode: promoCodeBox.predefinedPromoCode,
			promoCode: promoCodeBox.promoCode,
			returnDate: datePicker.currentReturn,
			teens: paxSelector.passengers.teens,
		});

		const validateForm = validate(form.current);
		const validateRoute = routeSelector.validate();
		const validateDates = datePicker.validate();
		const validatePaxSelector = paxSelector.validate();
		const validatePromoCode = promoCodeBox.validate();

		if (validateForm && validatePaxSelector && validatePromoCode && validateRoute && validateDates) {
			startLoading();

			savePreviousSearch();

			const request = getSearchRequest();
			const requestUrl = getRequestUrl(request);
			const isQueueItOn = toBoolean(window.JetSmart.DynamicSettings.IsQueueItOn);

			if (isQueueItOn) {
				redirectToQueueItUrl(requestUrl);
			} else {
				redirectToRegularUrl(requestUrl);
			}
		}
	};

	const handleFlightTabSelect = () => {
		setDisplayedTab("flights");
		raiseEventBusEvent("searchbox.flightsTab");
	};

	const handleHotelTabSelect = () => {
		setDisplayedTab("hotels");
		raiseEventBusEvent("searchbox.hotelsTab");
	};

	const handleCarTabSelect = () => {
		setDisplayedTab("cars");
		raiseEventBusEvent("searchbox.rentalcarsTab");
	};

	const handleTransferTabSelect = () => {
		setDisplayedTab("transfers");
		raiseEventBusEvent("searchbox.transfersTab");
	};

	useEffect(init, []);

	// Templates

	const reloadLinkTemplate = () =>
		showReloadLink
			? html`
					<div class="m-0 w-full px-0 pb-0 pt-2 text-center">
						<span
							class="cursor-pointer text-sm font-bold leading-none text-brand-secondary underline hover:text-brand-primary"
							@click=${handleReloadSearch}
						>
							&#8635;&nbsp;${i18next.t("Rearmar mi última búsqueda")}
						</span>
					</div>
			  `
			: "";

	const flightTabTemplate = () => html`
		<div
			class=${classNames("dg-tab dg-flights dg-for-loader", {
				"hidden": displayedTab !== "flights",
				"collapsed": isCollapsed,
				"with-reload": showReloadLink,
			})}
		>
			<form ref=${ref(form)} @click=${() => setIsCollapsed(false)} novalidate>
				${routeSelector.htmlTemplate()} ${tripTypeSelector.htmlTemplate()} ${datePicker.htmlTemplate()}

				<div class=${classNames("dg-clear-on-mobile", { "hidden-xs": isCollapsed })}>
					${paxSelector.htmlTemplate()} ${promoCodeBox.htmlTemplate()}
				</div>

				${reloadLinkTemplate()}

				<div class="dg-btn-container">
					<button
						class="dg-rounded-primary-btn"
						@click=${handleFormSubmit}
						data-test-id=${TestIdDictionary.SubmitSearchButton}
					>
						<i class="jsh-icon jsh-search"></i>
						<span>${i18next.t("search-box-submit")}</span>
					</button>
				</div>
			</form>
		</div>
	`;

	const hotelsTabTemplate = () => html`
		<div class=${classNames("dg-tab dg-hotels", { hidden: displayedTab !== "hotels" })}>
			<div id="hotel_container"></div>
		</div>
	`;

	const carsTabTemplate = () => {
		const carUrl = WIDGETS.get(props.culture.toLowerCase())?.carUrl || WIDGETS.get("default")?.carUrl;

		return html`
			<div class=${classNames("dg-tab dg-cars", { hidden: displayedTab !== "cars" })}>
				<iframe src=${carUrl} height="432" width="100%" scrolling="no" frameborder="0"></iframe>
			</div>
		`;
	};

	const transfersTabTemplate = () => {
		const transferUrl =
			WIDGETS.get(props.culture.toLowerCase())?.transferUrl || WIDGETS.get("default")?.transferUrl;

		return html`
			<div class=${classNames("dg-tab dg-transfers", { hidden: displayedTab !== "transfers" })}>
				<div class="transfer-credit">${i18next.t("search-box-transfer")} <span>Cartrawler</span></div>
				<iframe src=${transferUrl} height="432" width="100%" scrolling="no" frameborder="0"></iframe>
			</div>
		`;
	};

	const tabSelectorTemplate = (tab: Tab) => html`
		<li>
			<label class=${classNames({ [CLASS_NAMES.active]: tab.type === displayedTab })} @click=${tab.onClick}>
				<i class=${classNames("jsh-icon", { [tab.iconClass]: true })}></i>
				<span>${tab.label}</span>
			</label>
		</li>
	`;
	const tabs = (): Tab[] => [
		{
			type: "flights",
			label: i18next.t("search-box-tab-1"),
			iconClass: "jsh-plane",
			onClick: handleFlightTabSelect,
		},
		{
			type: "hotels",
			label: i18next.t("search-box-tab-2"),
			iconClass: "jsh-bed",
			onClick: handleHotelTabSelect,
		},
		{
			type: "cars",
			label: i18next.t("search-box-tab-3"),
			iconClass: "jsh-car",
			onClick: handleCarTabSelect,
		},
		{
			type: "transfers",
			label: i18next.t("search-box-tab-4"),
			iconClass: "jsh-van",
			onClick: handleTransferTabSelect,
		},
	];

	return html`
		<div class="searchbox-container" data-test-id=${TestIdDictionary.SearchboxContainer}>
			<div class="searchbox">
				<nav class="dg-tab-selector">
					<ul>
						${tabs().map((tab) => tabSelectorTemplate(tab))}
					</ul>
				</nav>
				${flightTabTemplate()} ${hotelsTabTemplate()} ${carsTabTemplate()} ${transfersTabTemplate()}
			</div>
		</div>
	`;
};
