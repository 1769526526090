/* eslint-disable @typescript-eslint/no-floating-promises */
import "isomorphic-fetch";
import smoothscroll from "smoothscroll-polyfill";

import "Services/Localization";

import * as globals from "Services/Globals";
import { EventBus } from "Services/eventbus/eventbus";

import i18next from "i18next";
import esCLPo from "../../../locale/translated/es-cl.client.json";
import enUSPo from "../../../locale/translated/en-us.client.json";
import ptBRPo from "../../../locale/translated/pt-br.client.json";
import { Debug } from "Services/Debug";
import { initCustomElements } from "./components/components";
import { sendAmplitudeExposure } from "Services/amplitude/AmplitudeHelper";
import { PubSub, PUB_SUBS } from "Services/pub-sub-service/PubSub";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt-br";

if ("serviceWorker" in navigator) {
	navigator.serviceWorker.register("../../../SalesManagoServiceWorker.js", { scope: "/" }).then(function () {
		// eslint-disable-next-line no-console
		console.log("Service Worker Registered");
	});
}

export const commonDebug = new Debug("js", "common");

if (!window.eventBus) {
	window.eventBus = new EventBus();
}

if (!window.PubSubs) {
	window.PubSubs = {
		VariantsReady: new PubSub<VariantsReady>(),
	};
}

document.addEventListener("click", (e: MouseEvent) => PUB_SUBS.DocumentClicked.publish({ mouseEvent: e }));

document.addEventListener("DOMContentLoaded", () => {
	window.exposeToAmplitude = sendAmplitudeExposure;
	window.PubSubs.VariantsReady.publish({
		variants: window.JetSmart.Variants,
	});
});

// Smooth scroll
(window as any).__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

dayjs.locale(globals.locale.indexOf("en") > -1 ? "en" : globals.locale.indexOf("pt") > -1 ? "pt-br" : "es");

// i18next

interface LocalizationEmbeddedLink {
	url: string;
	title: string;
}

async function initLocalization(): Promise<void> {
	await i18next.init(
		{
			initImmediate: true,
			nsSeparator: false,
			keySeparator: false,
			lng: globals.locale.indexOf("en") > -1 ? "enus" : globals.locale.indexOf("pt") > -1 ? "ptbr" : "escl",
			fallbackLng: ["escl"],
			returnEmptyString: false,
			resources: {
				// language: { namespace: resourse }
				escl: { translation: esCLPo },
				enus: { translation: enUSPo },
				ptbr: { translation: ptBRPo },
			},
			interpolation: {
				format: (value: any, format?: string) => {
					if (format === "custom" && (value as LocalizationEmbeddedLink)) {
						const linkObject = value as LocalizationEmbeddedLink;
						return `<a target="blank" href="${linkObject.url}">${linkObject.title}</a>`;
					}
					return value;
				},
			},
		},
		initCustomElements
	);
}

// DEVNOTE We have to init custom elements after i18next init, so that i18 strings appear
// right from the start. So we use this as callback of i18next.init
initLocalization();
